import { graphql } from 'gatsby'
import React from 'react'

import type { ModularPageBySlugQuery } from '../../graphql-types'
import Page from '../components/layout/Page'
import Module from '../components/modules'

export const query = graphql`
  query ModularPageBySlug($slug: String!) {
    page: contentfulModularPage(
      slug: { eq: $slug }
      node_locale: { eq: "en" }
    ) {
      ...ModularPage
    }
  }
`

interface Props {
  data: ModularPageBySlugQuery
}

const ModularPage = ({ data }: Props) => {
  const page = data?.page

  return (
    <Page
      head={{
        title: page?.title,
        description: page?.description?.description,
        image: page?.hero?.image?.file?.url,
        squareImage: page?.hero?.image?.file?.url,
      }}
    >
      <Module module={page?.hero} />

      {page?.modules?.map(
        (module, index) =>
          !!module && (
            <Module
              key={`${module.__typename || ''}${index}`}
              module={module}
            />
          ),
      )}
    </Page>
  )
}

export default ModularPage
